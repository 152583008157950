import axios from '@/common/js/request'

export function selectDisMaterialList(params){
    const selectDisMaterialList = axios({
        url: '/material/selectDisMaterialList',
        method: 'get',
        params
    })
    return selectDisMaterialList
}
export function selectMaterialOrderRecord(data){
    const selectMaterialOrderRecord = axios({
        url: '/material/selectMaterialOrderRecord',
        method: 'post',
        data
    })
    return selectMaterialOrderRecord
}
export function selectMaterialOrderRecordHead(data){
    const selectMaterialOrderRecordHead = axios({
        url:'/material/selectMaterialOrderRecordHead',
        method: 'post',
        data
    })
    return selectMaterialOrderRecordHead
}
export function toggleDisMaterial(params){
    const toggleDisMaterial = axios({
        url: '/material/toggleDisMaterial',
        method: 'get',
        params
    })
    return toggleDisMaterial
}
export function saveDisMaterial(params){
    const saveDisMaterial = axios({
        url: '/material/saveDisMaterial',
        method: 'post',
        data:params
    })
    return saveDisMaterial
}
export function selectMaterialPaymentRecord(params){
    const selectMaterialPaymentRecord = axios({
        url: '/material/selectMaterialPaymentRecord',
        method: 'post',
        data:params
    })
    return selectMaterialPaymentRecord
}
export function updateDisMaterial(params){
    const updateDisMaterial = axios({
        url: '/material/updateDisMaterial',
        method: 'post',
        data:params
    })
    return updateDisMaterial
}
export function selectMaterialPaymentTotal(params){
    const selectMaterialPaymentTotal = axios({
        url: '/material/selectMaterialPaymentTotal',
        method: 'post',
        data:params
    })
    return selectMaterialPaymentTotal
}
export function addRecord(params){
    const addRecord = axios({
        url: '/disRepairPlanRecord/add',
        method: 'post',
        data:params
    })
    return addRecord
}
export function updateRecord(params){
    const updateRecord = axios({
        url: '/disRepairPlanRecord/update',
        method: 'post',
        data:params
    })
    return updateRecord
}
export function addGroup(params){
    const addGroup = axios({
        url: '/disRepairPlanGroup/add',
        method: 'post',
        data:params
    })
    return addGroup
}
export function updateGroup(params){
    const updateGroup = axios({
        url: '/disRepairPlanGroup/update',
        method: 'post',
        data:params
    })
    return updateGroup
}
export function queryByPage(params){
    const queryByPage = axios({
        url: '/disRepairPlanRecord/queryByPage',
        method: 'get',
        params
    })
    return queryByPage
}
export function userList(params){
    const userList = axios({
        url: '/disRepairPlanGroup/userList',
        method: 'get',
        params
    })
    return userList
}
export function deleteusergroup(params){
    const deleteusergroup = axios({
        url: '/disRepairPlanGroup/delete',
        method: 'get',
        params
    })
    return deleteusergroup
}
export function GroupqueryByPage(params){
    const GroupqueryByPage = axios({
        url: '/disRepairPlanGroup/queryByPage',
        method: 'get',
        params
    })
    return GroupqueryByPage
}
export function queryByPagStatistic(params){
    const queryByPagStatistic = axios({
        url: '/disStatistics/queryByPage',
        method: 'get',
        params
    })
    return queryByPagStatistic
}
export function relieveRecord(params){
    const relieveRecord = axios({
        url: '/disRepairPlanRecord/relieve',
        method: 'get',
        params
    })
    return relieveRecord
}
export function groupList(params){
    const groupList = axios({
        url: '/disRepairPlanGroup/list',
        method: 'get',
        params
    })
    return groupList
}
export function excelSileData(data){  //
    const excelSileData = axios({
        url: '/material/exportMaterialOrderRecord',
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'blob' //改变接收的值类型
    })
    return excelSileData
}